.pagination ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .pagination li {
    display: inline-block;
    /* padding: 10px; */
    border-radius: 4px;
  }

  .pagination li a{
    color: var(--chakra-colors-text-gray);
    cursor: pointer;
    font-weight: 500;
    padding: 14px;
    
    background-color: #ecf0f1;
    margin: 1px;
  }

  .pagination .active a{
    color: var(--chakra-colors-primary-dark);
    font-weight: bold;
  }


  .pagination a:hover {
    background-color: #bdc3c7;
  }

  .leave-detail-container div:hover{
    background-color: rgba(236, 240, 241,.5);
  }
/* 
  .agent_tab div{
    background-color: white !important;
  } */

  .agent_tab button{
    border-radius: 0 !important;
  }

  .agent_tab {
    page-break-inside:avoid !important;
  }

  .agentConditionYesNo .chakra-checkbox__control{
    color: #3498DA !important;
  }

  .agentConditionYesNo .chakra-checkbox__label{
    opacity: 1 !important;
  }