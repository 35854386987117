@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&family=Roboto:ital,wght@1,100&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Hanuman:wght@300;400;700&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Moul:wght@400&display=swap"); */
@font-face { font-family: Moul; src: url('./assets/fonts/KHMERMEF2.ttf'); } 
@font-face { font-family: Hanuman; src: url('./assets/fonts/Hanuman/Hanuman-Regular.ttf'); } 
@font-face { font-family: Hanuman; font-weight: bold; src: url('./assets/fonts/Hanuman/Hanuman-Bold.ttf');}
@font-face { font-family: MEF1; src: url('./assets/fonts/KHMERMEF1.ttf'); } 
/* @font-face { font-family: Hanuman; font-weight: bold; src: url('./assets/fonts/KHMERMEF1.ttf');} */
@font-face { font-family: Bokor; src: url('./assets/fonts/Bokor-Regular.ttf'); } 
@import url("https://fonts.googleapis.com/css2?family=Nokora:wght@300;400;700&display=swap");
@font-face { font-family: NotoSansKhmer; src: url('./assets/fonts/NotoSansKhmer-Regular.ttf'); } 

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

th {
  font-family: "Rajdhani, Hanuman, Nokora, Bokor, sans-serif" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.swal-text {
 color:black;
}
.swal-button {
  color: white;
}