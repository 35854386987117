/* Styles go here */

body {
  margin: 0 auto;
  font-size: 14px;
  max-width: 100%;
  width: 100%;
}

@media print {

  .page-footer {
    position: fixed;
    background-color: white;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #62B3ED;
  }

  .footer, .t-foot {
      background: #fff;
  }

  .page-header {
    position: fixed;
    top: 0mm;
    width: 100%;
    border-bottom: 1px solid black; /* for demo */
  }
  
  .page {
    page-break-after: always;
  }
  
  thead {display: table-header-group;} 
  tfoot {display: table-footer-group;}
  
  button {display: none;}
  
  body {
    margin: 0;
    border: 0;
    background-color: white !important;
    zoom: 80%;
  }
} 
  
  