.my-table tbody tr:hover {
	background-color: #ecf0f1;
}

.my-table tr td:last-child {
	right: -2px !important;
}

@media screen and (max-width: 470px) {
    .my-table .table-header .title{
        display: none;
    }
}
