.pagination ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

.pagination li {
  display: inline-block;
  /* padding: 10px; */
  border-radius: 4px;
}

.pagination li a {
  color: var(--chakra-colors-text-gray);
  cursor: pointer;
  font-weight: 500;
  padding: 14px;

  background-color: #ecf0f1;
  margin: 1px;
}

.pagination .active a {
  color: var(--chakra-colors-primary-dark);
  font-weight: bold;
}


.pagination a:hover {
  background-color: #bdc3c7;
}

.tg-0lax {
  vertical-align: middle !important;
  text-align: center !important;
  background-color: #ecf0f1;
  font-family: "MEF1"
}

.tg {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  font-size: 10px;
  font-family: "MEF1"
}

.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  padding: 4px 4px;
  word-break: normal;
  font-family: "MEF1"
}

.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-weight: normal;
  overflow: hidden;
  padding: 4px 4px;
  word-break: normal;
  font-size: 10px !important;
  font-family: "MEF1"
}

.tg .tg-0lax {
  text-align: left;
  vertical-align: top
}

.tg input {
  font-size: 10px;
  height: 20px;
  padding: 2px;
  text-align: center;
}

.tableHeader {
  text-align: left !important;
  background-color: #ecf0f1;
}

/* 
  @media print {

    .tg {
      font-family: "MEF1" !important;
    }
  } */