.pagination ul {
	display: inline-block;
	padding-left: 15px;
	padding-right: 15px;
}

.pagination li {
	display: inline-block;
	border-radius: 4px;
}

.pagination li a {
	color: var(--chakra-colors-text-gray);
	cursor: pointer;
	font-weight: 500;
	padding: 14px;

	background-color: #ecf0f1;
	margin: 1px;
}

.pagination .active a {
	color: var(--chakra-colors-primary-dark);
	font-weight: bold;
}

.pagination a:hover {
	background-color: #bdc3c7;
}

.content-detail {
	margin-top: 20px;
}

.subcontent-detail {
	border: 1px solid #dbdbdb;
}

.tabs {
	margin-left: 16px;
	margin-right: 16px;
}

.content-tab {
	padding: 32px;
}

@media print {
	.content-detail {
		margin-top: 0px;
	}

	.subcontent-detail {
		border: 0px solid #dbdbdb;
	}

	.tabs {
		margin-left: 0px;
		margin-right: 0px;
	}

	.content-tab {
		padding: 0px;
	}
}
