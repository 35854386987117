
.printTableEmployee td{
    border: 1px solid black;
    text-align: left;
    padding: 8px;
}

.printTableEmployee th{
    border: 1px solid black;
    text-align: left;
    padding: 8px;
}


.printTableEmployee{
    width: 100%;
}

.textCenter{
    text-align: center !important;
}
