.disableForm {
	pointer-events: none;
	opacity: 0.5;
	user-select: none;
}

.disableFormClear {
	pointer-events: none;
	opacity: 1;
	user-select: none;
}

.disableButton {
	pointer-events: none;
	opacity: 0.8;
	user-select: none;
}
