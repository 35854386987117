.pagination ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .pagination li {
    display: inline-block;
    /* padding: 10px; */
    border-radius: 4px;
  }

  .pagination li a{
    color: var(--chakra-colors-text-gray);
    cursor: pointer;
    font-weight: 500;
    padding: 14px;
    
    background-color: #ecf0f1;
    margin: 1px;
  }

  .pagination .active a{
    color: var(--chakra-colors-primary-dark);
    font-weight: bold;
  }


  .pagination a:hover {
    background-color: #bdc3c7;
  }

  .avatarWraper:hover .remove {
    display: block !important;
  }

  tr {
    page-break-inside:avoid; page-break-after:auto 
  }